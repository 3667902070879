import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getUrlParam } from '@helpers';
import { NEW_API_URL } from '@constants';
import {
  Container,
  Hero,
  Loader,
  StaticError,
  StaticSuccess,
  Button,
} from '@components';
import MESSAGES from '@messages';

const errorsMap = {
  invalid_token: MESSAGES.ACTIVATE_ACCOUNT_INVALID_LINK,
  account_blocked: MESSAGES.ACCOUNT_BLOCKED,
  account_already_activated: MESSAGES.ACCOUNT_ALREADY_ACTIVATED,
};

class ActivateAccount extends PureComponent {
    state = {
      isLoading: true,
      error: null,
      successfull: true,
    }

    componentDidMount() {
      this.fetchData();
    }

    setMessage = (messages, cb) => {
      this.setState({
        isLoading: false,
        error: '',
        successfull: true,
        ...messages,
      }, () => {
        if (cb) cb();
      });
    }

    fetchData = () => {
      const token = getUrlParam('token', this.props.location);
      if (!token) {
        this.setState({ isLoading: false, error: errorsMap.invalid_token });
        return;
      }
      axios
        .get(`${NEW_API_URL}/activate-account/${token}`)
        .then(({ data }) => {
          if (data.ok) {
            this.setMessage({ successfull: true });
          }
        })
        .catch(({ response: { data = {} } = {} } = {}) => {
          const foundErrorMessage = errorsMap[data.message];
          this.setMessage({ error: foundErrorMessage || MESSAGES.DEFAULT_ERROR });
        });
    }

    renderContent = () => {
      const width = [1, 1, 1, 1 / 2];
      const { error, isLoading, successfull } = this.state;
      if (isLoading) return <Loader />;
      if (error) return <StaticError width={width}>{error}</StaticError>;
      if (successfull) {
        return (
          <>
            <StaticSuccess width={width}>{MESSAGES.ACTIVATE_ACCOUNT_SUCCESS}</StaticSuccess>
            <Button
              mt={[2, 3, 4]} small
              path="/harmonogram"
            >
              {MESSAGES.BACK_TO_SCHEDULE}
            </Button>
          </>
        );
      }

      return null;
    }

    render() {
      return (
        <Container textAlign="center">
          <Hero
            title={MESSAGES.ACTIVATE_ACCOUNT_TITLE}
            center
            seo={{
              metaTitle: MESSAGES.ACTIVATE_ACCOUNT_META_TITLE,
            }}
          />
          {this.renderContent()}
        </Container>
      );
    }
}

ActivateAccount.propTypes = {
  location: PropTypes.shape({}),
};

export default ActivateAccount;
